import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import {Link} from "react-router-dom";
import IUser from "./types/user.type";
import AuthService from "./services/auth.service";
import EventBus from "./common/EventBus";

// TODO - auslagern
interface Page {
    title: string;
    route: string;
}


const pages: Page[] = [
    {title: 'Home', route: '/home'},
    {title: 'User', route: '/user'},
];

// const settings:Page[] = [
//     {title: 'Profile', route: '/profile'},
//     {title: 'Moderator Board', route: '/mod'},
//     {title: 'Admin Board', route: '/admin'},
// ];

type State = {
    showModeratorBoard: boolean,
    showAdminBoard: boolean,
    currentUser: IUser | undefined,
    anchorElNav: null | HTMLElement,
    anchorElUser: null | HTMLElement
}

type Props = {};

class ResponsiveAppBar extends React.Component<{}, State> {
    constructor(props: {}) {
        super(props);
        this.logOut = this.logOut.bind(this);
        this.state = {
            anchorElNav: null,
            anchorElUser: null,
            showModeratorBoard: false,
            showAdminBoard: false,
            currentUser: undefined
        };
    }

    componentDidMount() {
        const user = AuthService.getCurrentUser();

        if (user) {
            this.setState({
                currentUser: user,
                showModeratorBoard: user.roles.includes("ROLE_MODERATOR"),
                showAdminBoard: user.roles.includes("ROLE_ADMIN"),
            });
        }

        EventBus.on("logout", this.logOut);
    }

    componentWillUnmount() {
        EventBus.remove("logout", this.logOut);
    }

    logOut() {
        AuthService.logout();
        this.setState({
            showModeratorBoard: false,
            showAdminBoard: false,
            currentUser: undefined,
        });
    }

    handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        this.setState({ anchorElNav: event.currentTarget });
    };

    handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        this.setState({ anchorElUser: event.currentTarget });
    };

    handleCloseNavMenu = () => {
        this.setState({ anchorElNav: null });
    };

    handleCloseUserMenu = () => {
        this.setState({ anchorElUser: null });
    };

    render() {
        const { anchorElNav, anchorElUser, currentUser, showModeratorBoard, showAdminBoard } = this.state;
        return (
            <AppBar position="static">
                <Container maxWidth="xl">
                    <Toolbar disableGutters>
                        <CheckCircleOutlineIcon sx={{display: {xs: 'none', md: 'flex'}, mr: 1}}/>
                        <Link to={"/"} className="navbar-brand">
                            <Typography
                                variant="h6"
                                noWrap
                                sx={{
                                    mr: 2,
                                    display: {xs: 'none', md: 'flex'},
                                    fontFamily: 'monospace',
                                    fontWeight: 700,
                                    letterSpacing: '.3rem',
                                    color: 'white',
                                    textDecoration: 'none',
                                }}
                            >
                                DEV
                            </Typography>
                        </Link>

                        {/* Mobile View Menu */}
                        <Box sx={{flexGrow: 1, display: {xs: 'flex', md: 'none'}}}>
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={this.handleOpenNavMenu}
                                color="inherit"
                            >
                                <MenuIcon/>
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorElNav}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                open={Boolean(anchorElNav)}
                                onClose={this.handleCloseNavMenu}
                                sx={{
                                    display: {xs: 'block', md: 'none'},
                                }}
                            >
                                {pages.map((page, index) => (
                                    <MenuItem key={page.route} onClick={this.handleCloseNavMenu}>
                                        <Link to={page.route}
                                              style={{textDecoration: 'none', color: 'inherit', width: '100%'}}>
                                            <Typography textAlign="center">{page.title}</Typography>
                                        </Link>
                                    </MenuItem>
                                ))}
                            </Menu>
                        </Box>
                        <CheckCircleOutlineIcon sx={{display: {xs: 'flex', md: 'none'}, mr: 1}}/>
                        <Typography
                            variant="h5"
                            noWrap
                            sx={{
                                mr: 2,
                                display: {xs: 'flex', md: 'none'},
                                flexGrow: 1,
                                fontFamily: 'monospace',
                                fontWeight: 700,
                                letterSpacing: '.3rem',
                                color: 'inherit',
                                textDecoration: 'none',
                            }}
                        >
                            DEV
                        </Typography>

                        {/* Desktop View Menu */}
                        <Box sx={{flexGrow: 1, display: {xs: 'none', md: 'flex'}}}>
                            {pages.map((page, index) => (
                                <Button
                                    key={page.route}
                                    component={Link}
                                    to={page.route}
                                    sx={{my: 2, color: 'white', display: 'block'}}
                                    style={{textDecoration: 'none', color: 'inherit'}}
                                >
                                    {page.title}
                                </Button>
                            ))}
                        </Box>
                        {currentUser ?  (
                        <Box sx={{flexGrow: 0}}>
                            <Tooltip title="Open settings">
                                <IconButton onClick={this.handleOpenUserMenu} sx={{p: 0}}>
                                    <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg"/>
                                </IconButton>
                            </Tooltip>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorElUser}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(anchorElUser)}
                                onClose={this.handleCloseUserMenu}
                            >
                                {/*{settings.map((setting, index) => (*/}
                                {/*    <MenuItem key={setting.route} onClick={this.handleCloseUserMenu}>*/}
                                {/*        <Link to={setting.route}*/}
                                {/*              style={{textDecoration: 'none', color: 'inherit', width: '100%'}}*/}
                                {/*        >*/}
                                {/*            <Typography textAlign="center">{setting.title}</Typography>*/}
                                {/*        </Link>*/}
                                {/*    </MenuItem>*/}
                                {/*))}*/}
                                <MenuItem>
                                    <Link to={"/profile"}
                                          style={{textDecoration: 'none', color: 'inherit', width: '100%'}}
                                    >
                                        <Typography textAlign="center">Profile {currentUser.username}</Typography>
                                    </Link>
                                </MenuItem>
                                <MenuItem>
                                    <Link to={"/tickets"}
                                          style={{textDecoration: 'none', color: 'inherit', width: '100%'}}
                                    >
                                        Support-Tickets
                                    </Link>
                                </MenuItem>
                                {showModeratorBoard && (
                                    <MenuItem>
                                        <Link to={"/mod"}
                                              style={{textDecoration: 'none', color: 'inherit', width: '100%'}}
                                        >
                                            <Typography textAlign="center">Moderator Board</Typography>
                                        </Link>
                                    </MenuItem>
                                )}
                                {showAdminBoard && (<div>
                                    <MenuItem>
                                        <Link to={"/admin"}
                                              style={{textDecoration: 'none', color: 'inherit', width: '100%'}}
                                        >
                                            Admin Board
                                        </Link>
                                    </MenuItem>
                                    <MenuItem>
                                        <Link to={"/adminmobile"}
                                              style={{textDecoration: 'none', color: 'inherit', width: '100%'}}
                                        >
                                            Admin Board Mobile
                                        </Link>
                                    </MenuItem>

                                    <MenuItem>
                                        <Link to={"/tickets/all"}
                                              style={{textDecoration: 'none', color: 'inherit', width: '100%'}}
                                        >
                                            Admin Support-Ticket
                                        </Link>
                                    </MenuItem>
                                </div>)}
                                <MenuItem>
                                    <Link to="/login"
                                          onClick={this.logOut}
                                          style={{textDecoration: 'none', color: 'red', width: '100%'}}
                                    >
                                        <Typography textAlign="center">LogOut</Typography>
                                    </Link>
                                </MenuItem>
                            </Menu>
                        </Box>
                        ) : (
                            <Box sx={{flexGrow: 0}}>
                                <Tooltip title="Open settings">
                                    <IconButton onClick={this.handleOpenUserMenu} sx={{p: 0}}>
                                        <Avatar src="/broken-image.jpg" />
                                    </IconButton>
                                </Tooltip>
                                <Menu
                                    id="menu-appbar"
                                    anchorEl={anchorElUser}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    open={Boolean(anchorElUser)}
                                    onClose={this.handleCloseUserMenu}
                                >
                                    <MenuItem>
                                        <Link to="/login"
                                              style={{textDecoration: 'none', color: 'inherit', width: '100%'}}
                                        >
                                            <Typography textAlign="center">Anmelden</Typography>
                                        </Link>
                                    </MenuItem>
                                    <MenuItem>
                                        <Link to="/register"
                                              style={{textDecoration: 'none', color: 'inherit', width: '100%'}}
                                        >
                                            <Typography textAlign="center">Konto erstellen</Typography>
                                        </Link>
                                    </MenuItem>
                                </Menu>
                            </Box>
                    )}
                    </Toolbar>
                </Container>
            </AppBar>
        );
    }
}

export default ResponsiveAppBar;
