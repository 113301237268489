import { Component } from "react";

type Props = {};

type State = {
    content: string;
};

export default class Impressum extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            content: "Impressum\n\nAngaben gemäß § 5 TMG\n\nFirma: Musterfirma GmbH\nAdresse: Musterstraße 1, 12345 Musterstadt\nGeschäftsführer: Max Mustermann\n\nKontakt:\nTelefon: +49 123 456789\nE-Mail: info@musterfirma.de\n\nRegistereintrag:\nEintragung im Handelsregister.\nRegistergericht: Musterstadt\nRegisternummer: HRB 12345\n\nUmsatzsteuer-ID:\nUmsatzsteuer-Identifikationsnummer gemäß §27 a Umsatzsteuergesetz: DE123456789\n\nVerantwortlich für den Inhalt nach § 55 Abs. 2 RStV:\nMax Mustermann\nMusterstraße 1, 12345 Musterstadt\n\nHaftungsausschluss: \nHaftung für Inhalte\nDie Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen. \n\nHaftung für Links\nUnser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. \n\nUrheberrecht\nDie durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Beiträge Dritter sind als solche gekennzeichnet. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet."
        };
    }

    render() {
        return (
            <div className="container">
                <header className="jumbotron text-center">
                    {/*<h3>Impressum</h3>*/}
                    {/*<pre style={{ whiteSpace: "pre-wrap" }}>{this.state.content}</pre>*/}
                    <h1>Impressum</h1>

                    <p>Niklas Wangler<br/>
                        Am Eisweiher 2<br/>
                        79189 Bad Krozingen</p>

                    <h2>Kontakt</h2>
                    {/*<p>Telefon: &#91;Telefonnummer&#93;<br/>*/}
                       <p> E-Mail: info@niklaswangler.de</p>

                    <h2>Redaktionell verantwortlich</h2>
                    <p>Niklas Wangler</p>

                    <h2>Streitschlichtung</h2>
                    <p>Wir sind nicht bereit oder verpflichtet, an
                        Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>

                    <p>Quelle: <a href="https://www.e-recht24.de">https://www.e-recht24.de</a></p>
                </header>
            </div>
        );
    }
}
