import axios from 'axios';
import authHeader from '../auth-header';
import {Ticket} from "../../types/ticket/ticket.type";

// const API_URL = 'http://localhost:8080/api';
const API_URL = 'https://vv4-backend.niklaswangler.de/api';

class TicketService {

    async createTicket(ticketData: Ticket): Promise<Ticket> {
        const response = await axios.post<Ticket>(API_URL + '/ticket', ticketData, { headers: authHeader() });
        return response.data;
    }

    async fetchTickets(): Promise<Ticket[]> {
        const response = await axios.get<Ticket[]>(API_URL + '/ticket/all', {headers: authHeader()});
        return response.data;
    }

    async getTicket(ticketId: number): Promise<Ticket> {
        return axios.get<Ticket>(API_URL + `/ticket/${ticketId}`, { headers: authHeader() })
            .then(response => response.data);
    }

    async getTicketsByUserId(userId: number): Promise<Ticket[]> {
        return axios.get<Ticket[]>(API_URL + `/ticket/user/${userId}`, { headers: authHeader() })
            .then(response => response.data);
    }

    async deleteTicket(ticketId: number): Promise<void> {
        return axios.delete(API_URL + `/ticket/${ticketId}`, { headers: authHeader() })
            .then(response => response.data);
    }

    async updateTicket(ticketId: number, ticketData: Ticket): Promise<Ticket> {
        return axios.put<Ticket>(API_URL + `/ticket/${ticketId}`, ticketData, { headers: authHeader() })
            .then(response => response.data);
    }
}

export default new TicketService();
