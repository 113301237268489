import { Component } from "react";
import {Link, Navigate} from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

import AuthService from "../services/auth.service";
import * as React from "react";

type Props = {};

type State = {
  redirect: string | null,
  username: string,
  password: string,
  loading: boolean,
  message: string
};

export default class Login extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.handleLogin = this.handleLogin.bind(this);

    this.state = {
      redirect: null,
      username: "",
      password: "",
      loading: false,
      message: ""
    };
  }

  componentDidMount() {
    const currentUser = AuthService.getCurrentUser();

    if (currentUser) {
      this.setState({ redirect: "/profile" });
    };
  }

  componentWillUnmount() {
    window.location.reload();
  }

  validationSchema() {
    return Yup.object().shape({
      username: Yup.string().required("This field is required!"),
      password: Yup.string().required("This field is required!"),
    });
  }

  handleLogin(formValue: { username: string; password: string }) {
    const { username, password } = formValue;

    this.setState({
      message: "",
      loading: true
    });


    AuthService.login(username, password).then(
      () => {
        this.setState({
          redirect: "/profile"
        });
      },
      error => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        this.setState({
          loading: false,
          message: resMessage
        });
      }
    );
  }

  render() {
    if (this.state.redirect) {
      return <Navigate to={this.state.redirect} />
    }

    const { loading, message } = this.state;

    const initialValues = {
      username: "",
      password: "",
    };

    return (
        <div className="flex justify-center items-center">
          <div className="bg-white shadow-md rounded-md p-8 max-w-md w-full">
            <h1 className="mx-auto text-center ock text-gray-700 text-3xl mb-2">Anmelden</h1>
            <img
                src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
                alt="profile-img"
                className="mx-auto w-24 rounded-full"
            />

            <Formik
                initialValues={initialValues}
                validationSchema={this.validationSchema}
                onSubmit={this.handleLogin}
            >
              {({isSubmitting}) => (
                  <Form className="mt-8">
                    <div className="mb-6">
                      <label htmlFor="username"
                             className="block text-gray-700 font-semibold mb-2"
                      >
                        Benutzername
                      </label>
                      <Field
                          name="username"
                          type="text"
                          className="w-full px-4 py-2 rounded-md border border-gray-300 focus:border-blue-500 focus:outline-none"
                      />
                      <ErrorMessage
                          name="username"
                          component="div"
                          className="text-red-500 text-sm mt-1"
                      />
                    </div>

                    <div className="mb-6">
                      <label htmlFor="password"
                             className="block text-gray-700 font-semibold mb-2"
                      >
                        Passwort
                      </label>
                      <Field
                          name="password"
                          type="password"
                          className="w-full px-4 py-2 rounded-md border border-gray-300 focus:border-blue-500 focus:outline-none"
                      />
                      <ErrorMessage
                          name="password"
                          component="div"
                          className="text-red-500 text-sm mt-1"
                      />
                    </div>

                    <div className="mb-6">
                      <button
                          type="submit"
                          className="w-full bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 transition duration-300"
                          disabled={isSubmitting}
                      >
                        {isSubmitting && (
                            <span className="inline-block mr-2 animate-spin">&#9696;</span>
                        )}
                        <span>Anmelden </span>
                      </button>
                    </div>

                    {message && (
                        <div className="text-red-500 text-sm mb-4">{message}</div>
                    )}

                    <Link
                        to="register"
                        className="block text-center text-blue-500 hover:text-blue-700"
                    >
                      Kein Konto? Neues Konto erstellen.
                    </Link>
                    <br />
                    <Link
                        to="forgotpassword"
                        className="block text-center text-blue-500 hover:text-blue-700"
                    >
                      Passwort vergessen?
                    </Link>


                  </Form>

              )}
            </Formik>
          </div>
        </div>

    );
  }
}
