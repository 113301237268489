import axios from 'axios';
import authHeader from '../auth-header';
import {Ticket} from "../../types/ticket/ticket.type";
import Documnet from "../../types/ticket/document.type";

// const API_URL = 'http://localhost:8080/api';
const API_URL = 'https://vv4-backend.niklaswangler.de/api';

class DokumentService {
    // async downloadFile(documentId: number): Promise<any> {
    //     return axios.get<any>(API_URL + `/document/${documentId}`, { headers: authHeader() })
    //         .then(response => response.data);
    // }

    // async downloadFile(documentId: number): Promise<any> {
    //     return axios.get<any>(`${API_URL}/document/${documentId}`, { headers: authHeader(), responseType: 'blob' })
    //         .then(response => {
    //             const url = window.URL.createObjectURL(new Blob([response.data]));
    //             const link = document.createElement('a');
    //             link.href = url;
    //             link.setAttribute('download', 'document.pdf'); // Set the document name and extension
    //             document.body.appendChild(link);
    //             link.click();
    //             // @ts-ignore
    //             link.parentNode.removeChild(link);
    //         });
    // }

    async downloadFile(documentId: number): Promise<void> {
        return axios.get<Uint8Array>(`${API_URL}/document/${documentId}`, { headers: authHeader(), responseType: 'arraybuffer' })
            .then(response => {
                console.log(response)
                const contentDisposition = response.headers['content-disposition'];
                let fileName = 'document1212';
                console.log(contentDisposition, "contentDisposition")

                if (contentDisposition) {
                    const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
                    console.log(fileNameMatch, "fileNameMatch");
                    // @ts-ignore
                    if (fileNameMatch.length === 2) {
                        if (fileNameMatch) {
                            fileName = fileNameMatch[1];
                        }
                    }
                }

                const blob = new Blob([response.data], { type: response.headers['content-type'] });
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName); // Set the document name and extension
                document.body.appendChild(link);
                link.click();
                // @ts-ignore
                link.parentNode.removeChild(link);
            });
    }


}

export default new DokumentService();
