import { Component } from "react";
import {Routes, Route, Link} from "react-router-dom";
import "./App.css";
import IUser from './types/user.type';
import Login from "./components/login.component";
import Register from "./components/register.component";
import Home from "./components/home.component";
import Profile from "./components/profile.component";
import BoardUser from "./components/board-user.component";
import BoardModerator from "./components/board-moderator.component";
import BoardAdmin from "./components/board-admin.component";

import ResponsiveAppBar from "./ResponsiveAppBar.component";
import ForgotPassword from "./components/forgot-password.component";
import EmailSent from "./components/email-sent.component";
import BoardAdminMobile from "./components/board-admin-mobil.component";
import TicketList from "./components/ticket/ticket-list.component";
import OwnTicketList from "./components/ticket/own-ticket-list.component";
import CreateTicket from "./components/ticket/create-ticket.component";
import Impressum from "./components/impressum.component";
import * as React from "react";
import Datenschutzerklaerung from "./components/datenschutzerklaerung.component";

type Props = {};

type State = {
  currentUser: IUser | undefined
}

class App extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      currentUser: undefined,
    };
  }

  render() {
    const { currentUser } = this.state;

    return (
        <div>
            <ResponsiveAppBar/>

            <div className="mt-3">
                <Routes>
                    <Route path="/" element={<Home/>}/>
                    <Route path="/home" element={<Home/>}/>
                    <Route path="/login" element={<Login/>}/>
                    <Route path="/login/register" element={<Register/>}/>
                    <Route path="/register" element={<Register/>}/>
                    <Route path="/profile" element={<Profile/>}/>
                    <Route path="/user" element={<BoardUser/>}/>
                    <Route path="/mod" element={<BoardModerator/>}/>
                    <Route path="/admin" element={<BoardAdmin/>}/>
                    <Route path="/login/forgotpassword" element={<ForgotPassword/>}/>
                    <Route path="/emailsent" element={<EmailSent/>}/>
                    <Route path="/adminmobile" element={<BoardAdminMobile/>}/>
                    <Route path="/tickets/all" element={<TicketList/>}/>
                    <Route path="/tickets" element={<OwnTicketList/>}/>
                    <Route path="/ticket/create" element={<CreateTicket/>}/>
                    <Route path="/impressum" element={<Impressum/>}/>
                    <Route path="/datenschutzerklaerung" element={<Datenschutzerklaerung/>}/>
                </Routes>
            </div>
            <br/>
            <br/>
            {/*<div className=" bottom-0 w-full" style={{ backgroundColor: '#2196f3' , width:'100%'}}>*/}
            {/*    <div className="flex justify-center py-4 text-white">*/}
            {/*        /!*<p className="text-white">©2024 Impressum und Datenschutz</p>*!/*/}
            {/*        <span className="">©2024 Niklas Wangler. Alle Rechte vorbehalten. </span>*/}
            {/*        <Link to={"/impressum"}*/}
            {/*              className="pr-4"*/}
            {/*              style={{textDecoration: 'none', color: 'inherit'}}*/}
            {/*        >*/}
            {/*            Impressum*/}
            {/*        </Link>*/}
            {/*        |*/}
            {/*        <Link to={"/datenschutzerklaerung"}*/}
            {/*              className="pl-4"*/}
            {/*              style={{textDecoration: 'none', color: 'inherit'}}*/}
            {/*        >*/}
            {/*            Datenschutzerklaerung*/}
            {/*        </Link>*/}
            {/*    </div>*/}
            {/*</div>*/}

            <div className="bottom-0 w-full" style={{backgroundColor: '#2196f3'}}>
                <div className="flex flex-col md:flex-row justify-center py-4 text-white text-center md:text-left">
                    <span className="mb-2 md:mb-0">©2024 Niklas Wangler. Alle Rechte vorbehalten.</span>
                    <div className="flex justify-center md:justify-start">
                        <Link
                            to="/impressum"
                            className="pr-2 md:px-4"
                            style={{textDecoration: 'none', color: 'inherit'}}
                        >
                            Impressum
                        </Link>
                        <span className="px-2 md:px-4">|</span>
                        <Link
                            to="/datenschutzerklaerung"
                            className="pl-2 md:pl-4"
                            style={{textDecoration: 'none', color: 'inherit'}}
                        >
                            Datenschutzerklaerung
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
  }
}

export default App;