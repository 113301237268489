import { Component } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

import AuthService from "../services/auth.service";
import * as React from "react";

type Props = {};

type State = {
  username: string,
  email: string,
  password: string,
  successful: boolean,
  message: string
};

export default class Register extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.handleRegister = this.handleRegister.bind(this);

    this.state = {
      username: "",
      email: "",
      password: "",
      successful: false,
      message: ""
    };
  }

  validationSchema() {
    return Yup.object().shape({
      username: Yup.string()
        .test(
          "len",
          "The username must be between 3 and 20 characters.",
          (val: any) =>
            val &&
            val.toString().length >= 3 &&
            val.toString().length <= 20
        )
        .required("This field is required!"),
      email: Yup.string()
        .email("This is not a valid email.")
        .required("This field is required!"),
      password: Yup.string()
        .test(
          "len",
          "The password must be between 6 and 40 characters.",
          (val: any) =>
            val &&
            val.toString().length >= 6 &&
            val.toString().length <= 40
        )
        .required("This field is required!"),
    });
  }

  handleRegister(formValue: { username: string; email: string; password: string }) {
    const { username, email, password } = formValue;

    this.setState({
      message: "",
      successful: false
    });

    AuthService.register(
      username,
      email,
      password
    ).then(
      response => {
        this.setState({
          message: response.data.message,
          successful: true
        });
      },
      error => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        this.setState({
          successful: false,
          message: resMessage
        });
      }
    );
  }

  render() {
    const { successful, message } = this.state;

    const initialValues = {
      username: "",
      email: "",
      password: "",
    };

    return (
      <div className="flex justify-center items-center">
        <div className="bg-white shadow-md rounded-md p-8 max-w-md w-full">
          <h1 className="mx-auto text-center ock text-gray-700 text-3xl mb-2">Konto erstellen</h1>
          <img
              src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
              alt="profile-img"
              className="mx-auto w-24 rounded-full"
          />

          <Formik
              initialValues={initialValues}
              validationSchema={this.validationSchema}
              onSubmit={this.handleRegister}
          >
            <Form className="mt-8">
              {!successful && (
                  <div>
                    <div className="mb-6">
                      <label htmlFor="username"
                             className="block text-gray-700 font-semibold mb-2"
                      >
                        Benutzername
                      </label>
                      <Field name="username"
                             type="text"
                             className="w-full px-4 py-2 rounded-md border border-gray-300 focus:border-blue-500 focus:outline-none"/>
                      <ErrorMessage
                          name="username"
                          component="div"
                          className="text-red-500 text-sm mt-1"
                      />
                    </div>

                    <div className="mb-6">
                      <label htmlFor="email"
                             className="block text-gray-700 font-semibold mb-2"
                      >
                        E-Mail
                      </label>
                      <Field name="email"
                             type="email"
                             className="w-full px-4 py-2 rounded-md border border-gray-300 focus:border-blue-500 focus:outline-none"/>
                      <ErrorMessage
                          name="email"
                          component="div"
                          className="text-red-500 text-sm mt-1r"
                      />
                    </div>

                    <div className="mb-6">
                      <label htmlFor="password"
                             className="block text-gray-700 font-semibold mb-2"
                      >
                        Passwort
                      </label>
                      <Field
                          name="password"
                          type="password"
                          className="w-full px-4 py-2 rounded-md border border-gray-300 focus:border-blue-500 focus:outline-none"
                      />
                      <ErrorMessage
                          name="password"
                          component="div"
                          className="text-red-500 text-sm mt-1"
                      />
                    </div>

                    <div className="mb-6">
                      <button type="submit"
                              className="w-full bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 transition duration-300"
                      >
                        Konto erstellen
                      </button>
                    </div>

                  </div>
              )}

              {message && (
                  <div className="form-group">
                    <div
                        className={
                          successful ? "alert alert-success" : "alert alert-danger"
                        }
                        role="alert"
                    >
                      {message}
                    </div>
                  </div>
              )}
            </Form>
          </Formik>
        </div>
      </div>
    );
  }
}
