import { Component } from "react";
import {Link} from "react-router-dom";
import * as React from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

type Props = {};

type State = {};

export default class EmailSent extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {};
    }

    render() {
        return (
            <div className="flex justify-center items-center">
                <div className="bg-white shadow-md rounded-md p-8 max-w-md w-full">
                    <Typography variant="subtitle1" align="center" color={"success.main"}>
                        Sie sollten in Kürze eine E-Mail zum Zurücksetzen des Passworts erhalten
                    </Typography>

                </div>
            </div>
        );
    }
}
