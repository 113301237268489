import axios from 'axios';
import authHeader from './auth-header';
import {User} from "../types/user.type";

// const API_URL = 'http://localhost:8080/api';
const API_URL = 'https://vv4-backend.niklaswangler.de/api';

class UserService {
  getPublicContent() {
    return axios.get(API_URL + '/test/all');
  }

  getUserBoard() {
    return axios.get(API_URL + '/test/user', { headers: authHeader() });
  }

  getModeratorBoard() {
    return axios.get(API_URL + '/test/mod', { headers: authHeader() });
  }

  getAdminBoard() {
    return axios.get(API_URL + '/test/admin', { headers: authHeader() });
  }

  async fetchUsers(): Promise<User[]> {
    const response = await axios.get<User[]>(API_URL + '/users/getusers', {headers: authHeader()});
    return response.data;
  }

  async getUser(userId: number): Promise<User> {
    return axios.get<User>(API_URL + `/users/${userId}`, { headers: authHeader() })
        .then(response => response.data);
  }

  async updateUser(userId: number, userData: User): Promise<User> {
    return axios.put<User>(API_URL + `/users/user/${userId}`, userData, { headers: authHeader() })
        .then(response => response.data);
  }

  async deleteUser(userId: number): Promise<void> {
    return axios.delete(API_URL + `/users/user/${userId}`, { headers: authHeader() })
        .then(response => response.data);
  }
}

export default new UserService();
