import { Component } from "react";
import {Navigate} from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import AuthService from "../services/auth.service";
import * as React from "react";

type Props = {};

type State = {
    redirect: string | null,
    email: string,
    loading: boolean,
    message: string
};

export default class ForgotPassword extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            redirect: null,
            email: "",
            loading: false,
            message: ""
        };
    }

    componentDidMount() {
        const currentUser = AuthService.getCurrentUser();

        if (currentUser) {
            this.setState({ redirect: "/home" });
        };
    }

    componentWillUnmount() {
        window.location.reload();
    }

    validationSchema() {
        return Yup.object().shape({
            email: Yup.string().required("This field is required!"),
        });
    }

    handleEmail = (formValue: { email: string; }) => { // Verwende Arrow-Funktion
        const { email } = formValue;

        this.setState({
            message: "",
            loading: true
        });


        AuthService.sendEmail(email).then(
            () => {
                this.setState({
                    redirect: "/emailsent"
                });
            },
            error => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                this.setState({
                    loading: false,
                    message: resMessage
                });
            }
        );
    }

    render() {
        if (this.state.redirect) {
            return <Navigate to={this.state.redirect} />
        }

        const { loading, message } = this.state;

        const initialValues = {
            email: "",
        };

        return (
            <div className="flex justify-center items-center">
                <div className="bg-white shadow-md rounded-md p-8 max-w-md w-full">
                    <h1 className="mx-auto text-center ock text-gray-700 text-3xl mb-2">Passwort zurücksetzen</h1>

                    <Formik
                        initialValues={initialValues}
                        validationSchema={this.validationSchema}
                        onSubmit={this.handleEmail} // Verwende die Arrow-Funktion
                    >
                        <Form className="mt-8">
                            <div className="mt-6">
                                <label htmlFor="email"
                                       className="block text-gray-700 font-semibold mb-2"
                                >
                                    E-Mail
                                </label>
                                <Field
                                    name="email"
                                    type="text"
                                    className="w-full px-4 py-2 rounded-md border border-gray-300 focus:border-blue-500 focus:outline-none"/>
                                <ErrorMessage
                                    name="email"
                                    component="div"
                                    className="text-red-500 text-sm mt-1"
                                />
                            </div>

                            <div className="mb-6 pt-5">
                                <button
                                    type="submit"
                                    className="w-full bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 transition duration-300"
                                    disabled={loading}
                                >
                                    {loading && (
                                        <span className="spinner-border spinner-border-sm"></span>
                                    )}
                                    <span>Passwort zurücksetzen</span>
                                </button>
                            </div>


                            {message && (
                                <div className="form-group">
                                    <div className="alert alert-danger" role="alert">
                                        {message}
                                    </div>
                                </div>
                            )}
                        </Form>
                    </Formik>
                </div>
            </div>
        );
    }
}
