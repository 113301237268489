import React, { Component } from "react";
import {Link, Navigate, redirect} from "react-router-dom";
import AuthService from "../services/auth.service";
import IUser, { User } from "../types/user.type";
import UserService from "../services/user.service";
import {Button, TextField} from "@mui/material";
import {DateField, DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFnsV3";
import EventBus from "../common/EventBus";

type Props = {};

type State = {
  user: User | any;
  redirect: string | null;
  userReady: boolean;
  currentUser: IUser & { accessToken: string };
  isLoading: boolean;
  error: string | null;
  successful: string | null;
};

export default class Profile extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      user: null,
      redirect: null,
      userReady: false,
      currentUser: { accessToken: "" },
      isLoading: false,
      error: null,
      successful: null
    };
  }

  componentDidMount() {
    const currentUser = AuthService.getCurrentUser();

    this.setState({successful: ""})

    if (!currentUser) this.setState({ redirect: "/home" });
    this.setState({ currentUser: currentUser, userReady: true });

    this.setState({ isLoading: true });
    UserService.getUser(currentUser.id)
        .then((user) => {
          this.setState({ user: user, isLoading: false });
        })
        .catch((error) => {
          this.setState({ error: error.message, isLoading: false });
        });
  }


  handleUserUpdate = (updatedUser: User) => {
    this.setState({ user: updatedUser });
  };

  changePassword = () => {
    this.setState({ redirect: "/forgotpassword" });
  //   TODO - change password add functionality - take rest api resetPassword
  };

  handleSave = () => {
    const { user } = this.state;

    // *** Deactivate Error Handling ***
    //     let errors: { [key: string]: string } = {};
    //
    //     errors = InputValidator.validateUserInputFields(changeUser, errors);
    //
    //     if (Object.keys(errors).length > 0) {
    //         this.setState({ errors });
    //         return; // Funktion wird hier beendet
    //     }

    UserService.updateUser(user.id, user).then(
        response => {
          this.setState({
            user: response
          });
        },
        error => {
          this.setState({
            user:
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
          });

          // logout if 401
          if (error.response && error.response.status === 401) {
            EventBus.dispatch("logout");
          }

        }
    );

  };

  handleDateChange = (date: Date | null, user: User) => {
    if (date) {
      const updatedUser = { ...user, birthdate: date.toISOString() };
      // wird bei on change ausgelöst bei  jeder änderung im Textfeld wird ein request gesenden - ist nur ein beispiel --> sinn macht es an der stelle keinen
      this.handleSaveUser(updatedUser);
    }
  };

  handleSaveUser = (user: User) => {
    UserService.updateUser(user.id, user)
        .then(response => {
          console.log('User updated successfully');
          this.handleUserUpdate(user);
        })
        .catch(error => {
          console.error('Error updating user:', error);
          // Handle error
        });
  };



  handleUpdateUser = () => {
    const { user } = this.state;
    if (user) {
      UserService.updateUser(user.id, user)
          .then((updatedUser) => {
            console.log("User updated successfully");
            this.setState({successful: "Daten wurden erfolgreich aktualisiert!"})
            // Optional: Redirect or show success message
          })
          .catch((error) => {
            console.error("Error updating user:", error);
            // Handle error, maybe show an error message to the user
          });
    }
  };

  handleInputChange = (field: keyof User, value: string) => {
    this.setState((prevState) => ({
      user: {
        ...prevState.user!,
        [field]: value,
      },
    }));
  };


  handleUserDelete = () => {
    const { user} = this.state;

// Bestätigungsfenster anzeigen
    if (window.confirm("Wollen Sie den Benutzer wirklich löschen?")) {

      // Nur wenn der Benutzer auf "OK" klickt, wird der Löschvorgang ausgeführt
      this.setState({ redirect: "/login" });
      UserService.deleteUser(user.id)
          .then(response => {
            console.log('User deleted successfully');
            EventBus.dispatch("logout");
          })
          .catch(error => {
            console.error('Error deleting user:', error);

            // logout if 401
            if (error.response && error.response.status === 401) {
              EventBus.dispatch("logout");
            }
          });
    } else {
      // Der Benutzer hat "Abbrechen" geklickt, daher wird nichts gelöscht
      console.log('Löschvorgang abgebrochen.');
    }

  };

  render() {
    if (this.state.redirect) {
      return <Navigate to={this.state.redirect} />;
    }

    const { currentUser, user, isLoading, error, successful } = this.state;

    return (
        <div className="">
          {isLoading && <p>Loading...</p>}
          {error && <p>{error}</p>}
          {user && (
              <div className="grid md:grid-cols-2 gap-4">
                <div>
                  <h1 className="text-2xl font-semibold mb-4">Herzlich Wilkommen <span className="text-4xl">{currentUser.username},</span></h1>
                  <h1 className="text-xl mb-4" >Hier kannst du deine Daten verwalten!</h1>
                </div>
                <div>
                </div>

                <TextField
                    label="Benutzername"
                    value={user.username}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    disabled
                />

                <TextField
                    label="E-Mail"
                    value={user.email}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    disabled
                />
                <TextField
                    label="Vorname"
                    value={user.firstName}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    onChange={(e) => this.handleUserUpdate({ ...user, firstName: e.target.value })}
                />
                <TextField
                    label="Nachname"
                    value={user.lastName}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    onChange={(e) => this.handleUserUpdate({ ...user, lastName: e.target.value })}
                />
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateField
                      label="Geburtsdatum"
                      value={new Date(user.birthdate)}
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      format="dd.MM.yyyy"
                      onChange={(date: Date | null) => this.handleDateChange(date, user)}
                  />
                    {/*<DatePicker*/}
                    {/*    label="Geburtsdatum"*/}
                    {/*    format="dd.MM.yyyy"*/}
                    {/*    value={new Date(user.birthdate)}*/}
                    {/*    onChange={(date: Date | null) => this.handleDateChange(date, user)}*/}
                    {/*/>*/}
                </LocalizationProvider>

                <TextField
                    label="Straße"
                    value={user.street}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    onChange={(e) => this.handleUserUpdate({ ...user, street: e.target.value })}
                />
                <TextField
                    label="Nr."
                    value={user.streetNumber}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    onChange={(e) => this.handleUserUpdate({ ...user, streetNumber: e.target.value })}
                />
                <TextField
                    label="PLZ"
                    value={user.plz}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    onChange={(e) => this.handleUserUpdate({ ...user, plz: e.target.value })}
                />
                <TextField
                    label="Ort"
                    value={user.location}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    onChange={(e) => this.handleUserUpdate({ ...user, location: e.target.value })}
                />
                <TextField
                    label="Land"
                    value={user.country}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    onChange={(e) => this.handleUserUpdate({ ...user, country: e.target.value })}
                />
                <div></div>
                <div>
                  <Button
                      className="w-full"
                      variant="contained"
                      color="success"
                      onClick={this.handleSave}
                  >
                    Speichern
                  </Button>
                </div>
                <div>
                  <div className="grid md:grid-cols-2 gap-4">
                    <Button
                        className="w-full"
                        variant="contained"
                        color="error"
                        onClick={() => this.handleUserDelete()}
                        disabled={true}
                    >
                      Löschen
                    </Button>
                  </div>
                </div>

                {/*          /!*TODO - add functionaliti  Benutzer löscehn und Passwort ändern*!/*/}


              </div>
          )}
        </div>
    );
  }
}
