import axios from "axios";

// const API_URL = "http://localhost:8080/api/auth";
const API_URL = 'https://vv4-backend.niklaswangler.de/api/auth';

class AuthService {
  login(username: string, password: string) {
    return axios
      .post(API_URL + "/signin", {
        username,
        password
      })
      .then(response => {
        if (response.data.accessToken) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }

        return response.data;
      });
  }

  logout() {
    localStorage.removeItem("user");
  }

  register(username: string, email: string, password: string) {
    return axios.post(API_URL + "/signup", {
      username,
      email,
      password
    });
  }

  getCurrentUser() {
    const userStr = localStorage.getItem("user");
    if (userStr) return JSON.parse(userStr);

    return null;
  }

  sendEmail(email: string) {
    console.log(email)
    return axios.post(API_URL + "/password/reset?email="+email);
  }
}

export default new AuthService();
