import React, { Component } from "react";
import { Button, CircularProgress, TextField, Alert } from "@mui/material";
import {DateField, DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import EventBus from "../../common/EventBus";
import {Ticket} from "../../types/ticket/ticket.type";
import TicketService from "../../services/ticket/ticket.service";
import {User} from "../../types/user.type";
import UserService from "../../services/user.service";
import DokumentService from "../../services/ticket/document.service";


type State = {
    tickets: Ticket[];
    filteredTickets: Ticket[];
    isLoading: boolean;
    error: string | null;
    changeTicket: Ticket | null;
    searchText: string;
    searchName: string; // Neu: Suchfeld für E-Mail-Adresse
    users: User[];
};

export default class TicketList extends Component<{}, State> {
    constructor(props: {}) {
        super(props);
        this.state = {
            tickets: [],
            filteredTickets: [],
            isLoading: false,
            error: null,
            changeTicket: null,
            searchText: "",
            searchName: "",
            users: []
        };
    }

    componentDidMount() {
        this.setState({ isLoading: true });
        TicketService.fetchTickets()
            .then(tickets => {
                this.setState({ tickets, filteredTickets: tickets, isLoading: false });
            })
            .catch(error => {
                this.setState({ error: error.message, isLoading: false });
            });
        this.setState({ isLoading: true });
        UserService.fetchUsers()
            .then(users => {
                this.setState({ users: users, isLoading: false });
            })
            .catch(error => {
                this.setState({ error: error.message, isLoading: false });
            });
    }


    handleDownload = (documentId: number) => {
        DokumentService.downloadFile(documentId)
            .then(() => {
                console.log('Document downloaded successfully');
            })
            .catch((error: any) => {
                console.error('Error downloading document:', error);
            });
    };

    handleTicketUpdate = (updatedTicket: Ticket) => {
        const { tickets } = this.state;
        const updatedTickets = tickets.map(ticket =>
            ticket.id === updatedTicket.id ? updatedTicket : ticket
        );
        this.setState({ tickets: updatedTickets, filteredTickets: updatedTickets, changeTicket: updatedTicket });
    };

    handleTicketDelete = (ticketId: number) => {
        TicketService.deleteTicket(ticketId)
            .then(response => {
                console.log("Ticket deleted successfully");
                this.setState(prevState => ({
                    tickets: prevState.tickets.filter(ticket => ticket.id !== ticketId),
                    filteredTickets: prevState.filteredTickets.filter(ticket => ticket.id !== ticketId)
                }));
            })
            .catch(error => {
                console.error("Error deleting Ticket:", error);
                // Handle error
            });
    };

    handleSave = () => {
        const { tickets, changeTicket } = this.state;

        if (!changeTicket) {
            // Wenn keine Änderungen am Benutzer vorgenommen wurden, einfach zurückkehren
            return;
        }

        // Überprüfen, ob es sich um ein neues Benutzerobjekt handelt
        const isNewTicket = !tickets.some(ticket => ticket.id === changeTicket.id);

        if (!isNewTicket) {
            // Vorhandenen Benutzer aktualisieren
            TicketService.updateTicket(changeTicket.id, changeTicket)
                .then(response => {
                    // Benutzerdaten werden aktualisiert
                    const updatedTickets = tickets.map(ticket => {
                        if (ticket.id === changeTicket.id) {
                            return changeTicket;
                        }
                        return ticket;
                    });
                    this.setState({
                        tickets: updatedTickets,
                        changeTicket: null // Zurücksetzen von changeTicket auf null, um das Bearbeiten zu beenden
                    });
                })
                .catch(error => {
                    this.setState({
                        error:
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString()
                    });

                    // logout if 401
                    if (error.response && error.response.status === 401) {
                        EventBus.dispatch("logout");
                    }
                });
        }
    };

    handleDateChange = (date: Date | null, ticket: Ticket) => {
        if (date) {
            const updatedTicket = { ...ticket, birthdate: date.toISOString() };
            // wird bei on change ausgelöst bei  jeder änderung im Textfeld wird ein request gesenden - ist nur ein beispiel --> sinn macht es an der stelle keinen
            this.handleSaveTicket(updatedTicket);
        }
    };

    handleSaveTicket = (ticket: Ticket) => {
        TicketService.updateTicket(ticket.id, ticket)
            .then(response => {
                console.log('Ticket updated successfully');
                this.handleTicketUpdate(ticket);
            })
            .catch(error => {
                console.error('Error updating Ticket:', error);
                // Handle error
            });
    };

    handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const searchText = event.target.value;
        this.setState({ searchText }, () => {
            this.filterTickets();
        });
    };

    handleNameSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const searchName = event.target.value;
        this.setState({ searchName }, () => {
            this.filterTickets();
        });
    };

    filterTickets = () => {
        const { tickets, searchText, searchName } = this.state;
        const filteredTickets = tickets.filter(ticket =>
            ticket.name.toLowerCase().includes(searchName.toLowerCase()) &&
            ticket.service.toLowerCase().includes(searchText.toLowerCase())
        );
        this.setState({ filteredTickets });
    };

    render() {
        const { filteredTickets, isLoading, error, searchText, searchName , users } = this.state;

        if (isLoading) {
            return <CircularProgress />;
        }

        if (error) {
            return <Alert severity="error">{error}</Alert>;
        }

        return (
            <div className="mx-auto px-4">
                <h1 className="text-2xl font-bold my-4">Ticket Liste ( Admin ) </h1>
                <div className="grid md:grid-cols-2 gap-4 ">
                    <TextField
                        label="Suche nach Service"
                        value={searchText}
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        onChange={this.handleSearchChange}
                    />
                    <TextField
                        label="Suche nach Ticket Namen" // Neu: Suchfeld für E-Mail-Adresse
                        value={searchName}
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        onChange={this.handleNameSearchChange}
                    />
                </div>
                <div className="grid md:grid-cols-3 gap-4 ">
                    {filteredTickets.map(ticket => (
                        <div key={ticket.id} className="bg-white rounded-lg p-4 shadow-md">
                            {users.map(user => (
                                ticket.userId === user.id ? (
                                    <TextField
                                        key={user.id}
                                        label="Benutzer"
                                        value={user.id + ", " + user.firstName + " " + user.lastName + ", " + user.location}
                                        variant="outlined"
                                        fullWidth
                                        margin="normal"
                                        disabled
                                    />
                                ) : null
                            ))}

                            <TextField
                                label="Id"
                                value={ticket.id}
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                disabled
                            />

                            <TextField
                                label="ticketCode"
                                value={ticket.ticketCode}
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                disabled
                            />
                            <TextField
                                label="name"
                                value={ticket.name}
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                onChange={(e) => this.handleTicketUpdate({...ticket, name: e.target.value})}
                            />
                            <TextField
                                label="description"
                                value={ticket.description}
                                variant="outlined"
                                fullWidth
                                multiline
                                rows={6}
                                margin="normal"
                                onChange={(e) => this.handleTicketUpdate({...ticket, description: e.target.value})}
                            />
                            {/* TODO add created and updated date */}
                            {/*<LocalizationProvider dateAdapter={AdapterDateFns}>*/}
                            {/*    <DateField*/}
                            {/*        label="Geburtsdatum"*/}
                            {/*        value={new Date(user.birthdate)}*/}
                            {/*        variant="outlined"*/}
                            {/*        fullWidth*/}
                            {/*        margin="normal"*/}
                            {/*        format="dd.MM.yyyy"*/}
                            {/*        onChange={(date: Date | null) => this.handleDateChange(date, user)}*/}
                            {/*    />*/}
                            {/*    <DatePicker*/}
                            {/*        label="Geburtsdatum"*/}
                            {/*        format="dd.MM.yyyy"*/}
                            {/*        value={new Date(user.birthdate)}*/}
                            {/*        onChange={(date: Date | null) => this.handleDateChange(date, user)}*/}
                            {/*    />*/}
                            {/*</LocalizationProvider>*/}

                            <TextField
                                label="service"
                                value={ticket.service}
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                onChange={(e) => this.handleTicketUpdate({...ticket, service: e.target.value})}
                            />
                            <TextField
                                label="status"
                                value={ticket.status}
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                onChange={(e) => this.handleTicketUpdate({...ticket, status: e.target.value})}
                            />
                            {/*<TextField*/}
                            {/*    label="userId"*/}
                            {/*    type="number"*/}
                            {/*    value={ticket.userId}*/}
                            {/*    variant="outlined"*/}
                            {/*    fullWidth*/}
                            {/*    margin="normal"*/}
                            {/*    onChange={(e) => this.handleTicketUpdate({ ...ticket, userId: e.target.value })}*/}
                            {/*/>*/}
                            {/* TODO - add documents */}
                            {/*<TextField*/}
                            {/*    label="Ort"*/}
                            {/*    value={ticket.documents}*/}
                            {/*    variant="outlined"*/}
                            {/*    fullWidth*/}
                            {/*    margin="normal"*/}
                            {/*    onChange={(e) => this.handleTicketUpdate({ ...ticket, location: e.target.value })}*/}
                            {/*/>*/}

                            {/* TODO - bei klick auf dokument soll es heruntergeladen weerden */}
                            {ticket.documents.map(document => (
                                <span
                                    key={document.id}
                                    className="text-xl my-4 cursor-pointer"
                                    style={{color: '#3f51b5'}}
                                    onClick={() => this.handleDownload(document.id)}
                                >
                                        | {document.name} |
                                    </span>
                            ))}

                            <div className="float-right mt-6">
                                <Button
                                    className="text-white"
                                    variant="contained"
                                    color="error"
                                    onClick={() => this.handleTicketDelete(ticket.id)}
                                >
                                    Löschen
                                </Button>

                                <span className="pl-10"></span>

                                <Button
                                    variant="contained"
                                    color="success"
                                    onClick={this.handleSave}
                                >
                                    Speichern
                                </Button>
                            </div>

                        </div>
                    ))}
                </div>
            </div>
        );
    }
}
