import React, { Component } from "react";
import { Button, CircularProgress, TextField, Alert } from "@mui/material";
import { User } from "../types/user.type";
import UserService from "../services/user.service";
import {DateField, DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import EventBus from "../common/EventBus";

type State = {
    users: User[];
    filteredUsers: User[];
    isLoading: boolean;
    error: string | null;
    changeUser: User | null;
    searchText: string;
    searchEmail: string; // Neu: Suchfeld für E-Mail-Adresse
};

export default class UserList extends Component<{}, State> {
    constructor(props: {}) {
        super(props);
        this.state = {
            users: [],
            filteredUsers: [],
            isLoading: false,
            error: null,
            changeUser: null,
            searchText: "",
            searchEmail: "" // Initialisierung des Suchfelds für E-Mail-Adresse
        };
    }

    componentDidMount() {
        this.setState({ isLoading: true });
        UserService.fetchUsers()
            .then(users => {
                this.setState({ users, filteredUsers: users, isLoading: false });
            })
            .catch(error => {
                this.setState({ error: error.message, isLoading: false });
            });
    }

    handleUserUpdate = (updatedUser: User) => {
        const { users } = this.state;
        const updatedUsers = users.map(user =>
            user.id === updatedUser.id ? updatedUser : user
        );
        this.setState({ users: updatedUsers, filteredUsers: updatedUsers, changeUser: updatedUser });
    };

    handleUserDelete = (deletedUserId: number) => {
        UserService.deleteUser(deletedUserId)
            .then(response => {
                console.log("User deleted successfully");
                this.setState(prevState => ({
                    users: prevState.users.filter(user => user.id !== deletedUserId),
                    filteredUsers: prevState.filteredUsers.filter(user => user.id !== deletedUserId)
                }));
            })
            .catch(error => {
                console.error("Error deleting user:", error);
                // Handle error
            });
    };

    handleSave = () => {
        const { users, changeUser } = this.state;

        if (!changeUser) {
            // Wenn keine Änderungen am Benutzer vorgenommen wurden, einfach zurückkehren
            return;
        }

        // Überprüfen, ob es sich um ein neues Benutzerobjekt handelt
        const isNewUser = !users.some(user => user.id === changeUser.id);

        if (!isNewUser) {
            // Vorhandenen Benutzer aktualisieren
            UserService.updateUser(changeUser.id, changeUser)
                .then(response => {
                    // Benutzerdaten werden aktualisiert
                    const updatedUsers = users.map(user => {
                        if (user.id === changeUser.id) {
                            return changeUser;
                        }
                        return user;
                    });
                    this.setState({
                        users: updatedUsers,
                        changeUser: null // Zurücksetzen von changeUser auf null, um das Bearbeiten zu beenden
                    });
                })
                .catch(error => {
                    this.setState({
                        error:
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString()
                    });

                    // logout if 401
                    if (error.response && error.response.status === 401) {
                        EventBus.dispatch("logout");
                    }
                });
        }
    };

    handleDateChange = (date: Date | null, user: User) => {
        if (date) {
            const updatedUser = { ...user, birthdate: date.toISOString() };
            // wird bei on change ausgelöst bei  jeder änderung im Textfeld wird ein request gesenden - ist nur ein beispiel --> sinn macht es an der stelle keinen
            this.handleSaveUser(updatedUser);
        }
    };

    handleSaveUser = (user: User) => {
        UserService.updateUser(user.id, user)
            .then(response => {
                console.log('User updated successfully');
                this.handleUserUpdate(user);
            })
            .catch(error => {
                console.error('Error updating user:', error);
                // Handle error
            });
    };

    handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const searchText = event.target.value;
        this.setState({ searchText }, () => {
            this.filterUsers();
        });
    };

    handleEmailSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const searchEmail = event.target.value;
        this.setState({ searchEmail }, () => {
            this.filterUsers();
        });
    };

    filterUsers = () => {
        const { users, searchText, searchEmail } = this.state;
        const filteredUsers = users.filter(user =>
            user.username.toLowerCase().includes(searchText.toLowerCase()) &&
            user.email.toLowerCase().includes(searchEmail.toLowerCase())
        );
        this.setState({ filteredUsers });
    };

    render() {
        const { filteredUsers, isLoading, error, searchText, searchEmail } = this.state;

        if (isLoading) {
            return <CircularProgress />;
        }

        if (error) {
            return <Alert severity="error">{error}</Alert>;
        }

        return (
            <div className="mx-auto px-4">
                <h1 className="text-2xl font-bold my-4">Benutzer Liste</h1>
                <div className="grid md:grid-cols-2 gap-4 ">
                    <TextField
                        label="Suche nach Benutzername"
                        value={searchText}
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        onChange={this.handleSearchChange}
                    />
                    <TextField
                        label="Suche nach E-Mail-Adresse" // Neu: Suchfeld für E-Mail-Adresse
                        value={searchEmail}
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        onChange={this.handleEmailSearchChange}
                    />
                </div>
                <div className="grid md:grid-cols-3 gap-4 ">
                    {filteredUsers.map(user => (
                        <div key={user.id} className="bg-white rounded-lg p-4 shadow-md">
                            <TextField
                                label="Benutzername"
                                value={user.username}
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                disabled
                            />

                            <TextField
                                label="E-Mail"
                                value={user.email}
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                disabled
                            />
                            <TextField
                                label="Vorname"
                                value={user.firstName}
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                onChange={(e) => this.handleUserUpdate({ ...user, firstName: e.target.value })}
                            />
                            <TextField
                                label="Nachname"
                                value={user.lastName}
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                onChange={(e) => this.handleUserUpdate({ ...user, lastName: e.target.value })}
                            />
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DateField
                                    label="Geburtsdatum"
                                    value={new Date(user.birthdate)}
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    format="dd.MM.yyyy"
                                    onChange={(date: Date | null) => this.handleDateChange(date, user)}
                                />
                                {/*<DatePicker*/}
                                {/*    label="Geburtsdatum"*/}
                                {/*    format="dd.MM.yyyy"*/}
                                {/*    value={new Date(user.birthdate)}*/}
                                {/*    onChange={(date: Date | null) => this.handleDateChange(date, user)}*/}
                                {/*/>*/}
                            </LocalizationProvider>

                            <TextField
                                label="Straße"
                                value={user.street}
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                onChange={(e) => this.handleUserUpdate({ ...user, street: e.target.value })}
                            />
                            <TextField
                                label="Nr."
                                value={user.streetNumber}
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                onChange={(e) => this.handleUserUpdate({ ...user, streetNumber: e.target.value })}
                            />
                            <TextField
                                label="PLZ"
                                value={user.plz}
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                onChange={(e) => this.handleUserUpdate({ ...user, plz: e.target.value })}
                            />
                            <TextField
                                label="Ort"
                                value={user.location}
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                onChange={(e) => this.handleUserUpdate({ ...user, location: e.target.value })}
                            />
                            <TextField
                                label="Land"
                                value={user.country}
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                onChange={(e) => this.handleUserUpdate({ ...user, country: e.target.value })}
                            />
                            <div className="float-right">
                                <Button
                                    className="text-white"
                                    variant="contained"
                                    color="error"
                                    onClick={() => this.handleUserDelete(user.id)}
                                >
                                    Löschen
                                </Button>

                                <span className="pl-10"></span>

                                <Button
                                    variant="contained"
                                    color="success"
                                    onClick={this.handleSave}
                                >
                                    Speichern
                                </Button>
                            </div>

                        </div>
                    ))}
                </div>
            </div>
        );
    }
}
