import React, { useState, useEffect } from "react";
import { Button, CircularProgress, TextField, Alert } from "@mui/material";
import { Ticket } from "../../types/ticket/ticket.type";
import Document from "../../types/ticket/document.type";
import TicketService from "../../services/ticket/ticket.service";
import { Navigate } from "react-router-dom";
import IUser from "../../types/user.type";
import AuthService from "../../services/auth.service";

const CreateTicket: React.FC = () => {
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [documents, setDocuments] = useState<Document[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [success, setSuccess] = useState<string | null>(null);
    const [redirect, setRedirect] = useState<string | null>(null);
    const [currentUser, setCurrentUser] = useState<IUser & { accessToken: string }>({ accessToken: "" });

    const VALID_MIME_TYPES: string[] = [
        "application/pdf",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.ms-powerpoint",
        "application/vnd.openxmlformats-officedocument.presentationml.presentation",
        "text/plain",
        "text/csv",
        "application/rtf",
        "image/jpeg",
        "image/png",
        "image/gif",
        "image/bmp",
        "image/svg+xml",
        "audio/mpeg",
        "audio/wav",
        "audio/ogg",
        "video/mp4",
        "video/x-msvideo",
        "video/x-ms-wmv",
        "video/quicktime",
        "application/zip",
        "application/x-tar",
        "application/x-rar-compressed",
        "application/x-7z-compressed"
    ];

    useEffect(() => {
        const currentUser = AuthService.getCurrentUser();
        setCurrentUser(currentUser);
    }, []);

    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const files = event.target.files;
        if (files && files.length > 0) {
            const file = files[0];

            if (!VALID_MIME_TYPES.includes(file.type)) {
                alert('Invalid file type.');
                return;
            }

            const base64 = await convertToBase64(file);
            const rowbase64 = base64.split(',')[1];
            const updatedDocuments = [...documents];
            console.log(rowbase64);
            updatedDocuments[index] = {
                ...updatedDocuments[index],
                file: rowbase64,
                name: file.name,
                type: file.type,
            };
            setDocuments(updatedDocuments);
        }
    };

    const convertToBase64 = (file: File): Promise<string> => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result as string);
            reader.onerror = error => reject(error);
        });
    };

    const addDocument = () => {
        setDocuments([...documents, {
            id: 0,
            name: "",
            description: "",
            file: "",
            type: "",
            categories: "",
            status: "",
            ticketId: 0,
        }]);
    };

    const handleSubmit = async () => {
        setIsLoading(true);
        setError(null);
        setSuccess(null);

        const ticketData: Ticket = {
            id: 0,
            ticketCode: "",
            name,
            description,
            service: "Support",
            status: "offen",
            userId: currentUser.id,
            documents,
        };

        try {
            await TicketService.createTicket(ticketData);
            setSuccess("Ticket erfolgreich erstellt");
        } catch (error) {
            setError("Fehler beim Erstellen des Tickets");
        } finally {
            setIsLoading(false);
        }
        setRedirect("/tickets");
    };

    return (
        <div className="mx-auto px-4">
            {redirect && <Navigate to={redirect} />}
            <h1 className="text-2xl font-bold my-4">Neues Ticket erstellen</h1>
            {error && <Alert severity="error">{error}</Alert>}
            {success && <Alert severity="success">{success}</Alert>}
            <TextField
                label="Name"
                value={name}
                variant="outlined"
                fullWidth
                margin="normal"
                onChange={(e) => setName(e.target.value)}
            />
            <TextField
                label="Beschreibung"
                value={description}
                variant="outlined"
                fullWidth
                multiline
                rows={12}
                margin="normal"
                onChange={(e) => setDescription(e.target.value)}
            />
            {documents.map((document, index) => (
                <div key={index} className="mb-4">
                    <TextField
                        label="Dokumentbeschreibung"
                        value={document.description}
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        onChange={(e) => {
                            const updatedDocuments = [...documents];
                            updatedDocuments[index].description = e.target.value;
                            setDocuments(updatedDocuments);
                        }}
                    />
                    <input
                        type="file"
                        onChange={(e) => handleFileChange(e, index)}
                    />
                </div>
            ))}
            <Button variant="contained" color="primary" onClick={addDocument}>
                Dokument hinzufügen
            </Button>
            <div className="mt-4">
                <Button
                    variant="contained"
                    color="success"
                    onClick={handleSubmit}
                    disabled={isLoading}
                >
                    {isLoading ? <CircularProgress size={24} /> : "Ticket erstellen"}
                </Button>
            </div>
        </div>
    );
};

export default CreateTicket;
