import React, { Component } from "react";
import {
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField
} from "@mui/material";
import { Delete, Save } from "@mui/icons-material";
import UserService from "../services/user.service";
import { User } from "../types/user.type";
import {DateField, DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";

type State = {
    users: User[];
    isLoading: boolean;
    error: string | null;
};

export default class UserTable extends Component<{}, State> {
    constructor(props: {}) {
        super(props);
        this.state = {
            users: [],
            isLoading: false,
            error: null
        };
    }

    componentDidMount() {
        this.setState({ isLoading: true });
        UserService.fetchUsers()
            .then(users => {
                this.setState({ users, isLoading: false });
            })
            .catch(error => {
                this.setState({ error: error.message, isLoading: false });
            });
    }

    handleUserUpdate = (updatedUser: User) => {
        const updatedUsers = this.state.users.map(user => {
            if (user.id === updatedUser.id) {
                return updatedUser;
            }
            return user;
        });
        this.setState({ users: updatedUsers });
    };

    handleUserDelete = (deletedUserId: number) => {
        UserService.deleteUser(deletedUserId)
            .then(response => {
                console.log('User deleted successfully');
                const updatedUsers = this.state.users.filter(user => user.id !== deletedUserId);
                this.setState({ users: updatedUsers });
            })
            .catch(error => {
                console.error('Error deleting user:', error);
                // Handle error
            });
    };

    handleSaveUser = (user: User) => {
        UserService.updateUser(user.id, user)
            .then(response => {
                console.log('User updated successfully');
                this.handleUserUpdate(user);
            })
            .catch(error => {
                console.error('Error updating user:', error);
                // Handle error
            });
    };

    handleDateChange = (date: Date | null, user: User) => {
        if (date) {
            const updatedUser = { ...user, birthdate: date.toISOString() };
            this.handleSaveUser(updatedUser);
        }
    };

    render() {
        const { users, isLoading, error } = this.state;

        if (isLoading) {
            return <div>Loading...</div>;
        }

        if (error) {
            return <div>Error: {error}</div>;
        }

        return (
            <div className=" mx-auto px-4">
                <h1 className="text-2xl font-bold my-4">Benutzer Liste</h1>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Id</TableCell>
                            <TableCell>Benutzername</TableCell>
                            <TableCell>E-Mail</TableCell>
                            <TableCell>Vorname</TableCell>
                            <TableCell>Nachname</TableCell>
                            <TableCell>Geburtsdatum</TableCell>
                            <TableCell>Straße</TableCell>
                            <TableCell>Nr.</TableCell>
                            <TableCell>PLZ</TableCell>
                            <TableCell>Ort</TableCell>
                            <TableCell>Land</TableCell>
                            <TableCell>Aktionen</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {users.map((user) => (
                            <TableRow key={user.id}>
                                <TableCell>
                                    <TextField
                                        defaultValue={user.id}
                                        disabled={true}
                                    />
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        defaultValue={user.username}
                                        onChange={(event) => {
                                            const updatedUser = { ...user, username: event.target.value };
                                            this.handleUserUpdate(updatedUser);
                                        }}
                                    />
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        defaultValue={user.email}
                                        onChange={(event) => {
                                            const updatedUser = { ...user, email: event.target.value };
                                            this.handleUserUpdate(updatedUser);
                                        }}
                                    />
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        defaultValue={user.firstName}
                                        onChange={(event) => {
                                            const updatedUser = { ...user, firstName: event.target.value };
                                            this.handleUserUpdate(updatedUser);
                                        }}
                                    />
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        defaultValue={user.lastName}
                                        onChange={(event) => {
                                            const updatedUser = { ...user, lastName: event.target.value };
                                            this.handleUserUpdate(updatedUser);
                                        }}
                                    />
                                </TableCell>
                                {/*** INFO - example mehrere felder in einen textfeld bearbeiten */}
                                <TableCell>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DateField
                                            value={new Date(user.birthdate)}
                                            format="dd.MM.yyyy"
                                            onChange={(date: Date | null) => this.handleDateChange(date, user)}
                                        />
                                        {/*<DatePicker*/}
                                        {/*    value={new Date(user.birthdate)}*/}
                                        {/*    format="dd.MM.yyyy"*/}
                                        {/*    onChange={(date: Date | null) => this.handleDateChange(date, user)}*/}
                                        {/*/>*/}
                                    </LocalizationProvider>
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        defaultValue={user.street}
                                        onChange={(event) => {
                                            const updatedUser = { ...user, street: event.target.value };
                                            this.handleUserUpdate(updatedUser);
                                        }}
                                    />
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        defaultValue={user.streetNumber}
                                        onChange={(event) => {
                                            const updatedUser = { ...user, streetNumber: event.target.value };
                                            this.handleUserUpdate(updatedUser);
                                        }}
                                    />
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        defaultValue={user.plz}
                                        onChange={(event) => {
                                            const updatedUser = { ...user, plz: event.target.value };
                                            this.handleUserUpdate(updatedUser);
                                        }}
                                    />
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        defaultValue={user.location}
                                        onChange={(event) => {
                                            const updatedUser = { ...user, location: event.target.value };
                                            this.handleUserUpdate(updatedUser);
                                        }}
                                    />
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        defaultValue={user.country}
                                        onChange={(event) => {
                                            const updatedUser = { ...user, country: event.target.value };
                                            this.handleUserUpdate(updatedUser);
                                        }}
                                    />
                                </TableCell>
                                <TableCell>
                                    <IconButton
                                        color="success"
                                        onClick={() => this.handleSaveUser(user)}
                                    >
                                        <Save />
                                    </IconButton>
                                    <IconButton
                                        color="error"
                                        onClick={() => this.handleUserDelete(user.id)}
                                    >
                                        <Delete />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </div>
        );
    }
}
